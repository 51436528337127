import React from "react"

interface IProps {
  size?: number
  className?: string
}

export default function NDWLogo({ size = 200, className }: IProps) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(553 / 1377) * size}
      viewBox="0 0 1377 553"
      preserveAspectRatio="xMidYMid meet"
      className={`text-gray-50 ${className}`}
    >
      <g
        transform="translate(0.000000,553) scale(0.100000,-0.100000)"
        // fill="#F9FAFB"
        stroke="none"
        className="fill-current"
      >
        <path
          d="M3470 5520 c-285 -45 -440 -287 -334 -520 61 -134 196 -214 445 -264
216 -43 301 -83 339 -160 72 -150 -54 -294 -274 -313 -215 -18 -400 106 -460
308 -6 20 -15 38 -20 40 -4 1 -44 -20 -87 -47 -89 -55 -87 -47 -38 -149 64
-135 184 -241 329 -291 102 -35 282 -44 393 -20 155 35 272 118 329 234 30 61
33 74 33 162 0 117 -24 177 -95 244 -79 74 -168 110 -422 169 -220 52 -318
126 -318 242 0 125 118 203 294 193 141 -8 238 -71 291 -190 15 -32 30 -58 34
-56 43 19 161 92 161 99 0 26 -58 119 -107 170 -58 61 -136 108 -223 133 -68
20 -197 28 -270 16z"
        />
        <path
          d="M80 4810 l0 -700 90 0 90 0 0 625 c0 344 3 625 8 625 4 -1 167 -282
362 -625 l355 -625 163 0 162 0 -2 698 -3 697 -90 0 -90 0 -5 -628 -5 -628
-360 630 -360 631 -157 0 -158 0 0 -700z"
        />
        <path
          d="M2081 5488 c-5 -13 -135 -325 -290 -694 -155 -370 -281 -675 -281
-678 0 -4 45 -5 101 -4 l100 3 71 173 70 172 367 0 367 0 69 -175 70 -175 104
0 c78 0 102 3 98 13 -3 6 -134 321 -293 700 l-288 687 -128 0 c-124 0 -129 -1
-137 -22z m289 -501 c78 -191 140 -350 138 -353 -3 -2 -136 -3 -296 -2 l-290
3 146 357 c81 197 150 354 153 350 4 -4 71 -164 149 -355z"
        />
        <path
          d="M4370 4810 l0 -700 95 0 95 0 0 300 0 300 400 0 400 0 0 -300 0 -300
90 0 90 0 0 700 0 700 -90 0 -90 0 0 -315 0 -315 -400 0 -400 0 -2 313 -3 312
-92 3 -93 3 0 -701z"
        />
        <path
          d="M5743 5493 c2 -10 129 -319 282 -688 152 -368 279 -676 282 -683 4
-9 37 -12 137 -10 l131 3 287 695 287 695 -96 3 c-53 1 -100 -1 -104 -5 -4 -4
-119 -286 -255 -626 -136 -339 -251 -614 -255 -610 -4 5 -118 286 -254 626
l-247 617 -100 0 c-91 0 -100 -2 -95 -17z"
        />
        <path d="M7340 4810 l0 -700 95 0 95 0 -2 698 -3 697 -92 3 -93 3 0 -701z" />
        <path
          d="M7810 4810 l0 -700 520 0 520 0 0 85 0 85 -430 0 -430 0 0 615 0 615
-90 0 -90 0 0 -700z"
        />
        <path
          d="M9080 4810 l0 -700 520 0 520 0 0 85 0 85 -430 0 -430 0 0 615 0 615
-90 0 -90 0 0 -700z"
        />
        <path
          d="M10350 4810 l0 -700 500 0 500 0 0 85 0 85 -410 0 -410 0 0 230 0
230 370 0 370 0 0 90 0 90 -370 0 -370 0 0 205 0 205 400 0 400 0 0 90 0 90
-490 0 -490 0 0 -700z"
        />
        <path
          d="M3100 3436 c-157 -44 -247 -147 -258 -294 -9 -139 49 -266 161 -348
32 -24 137 -84 233 -135 238 -125 284 -177 284 -322 0 -92 -32 -146 -115 -190
-33 -18 -57 -22 -135 -22 -84 0 -102 3 -152 28 -101 50 -163 144 -195 300
l-18 87 -42 0 -43 0 0 -240 0 -240 40 0 c34 0 42 4 50 24 5 13 13 26 17 29 5
3 49 -10 98 -30 77 -31 103 -36 196 -41 132 -6 217 9 295 52 108 60 164 162
164 301 0 132 -47 231 -145 308 -31 24 -134 84 -228 133 -255 131 -315 197
-305 334 8 117 92 189 232 198 100 6 174 -16 233 -71 62 -57 96 -132 118 -259
6 -36 8 -38 45 -38 l40 0 0 220 0 220 -35 0 c-30 0 -37 -4 -49 -35 -8 -19 -17
-35 -19 -35 -3 0 -30 12 -62 26 -31 15 -80 32 -108 40 -64 17 -236 17 -297 0z"
        />
        <path
          d="M5084 3435 c-277 -60 -475 -260 -530 -534 -20 -101 -15 -281 10 -376
24 -90 82 -197 143 -265 137 -151 311 -220 555 -220 185 0 323 37 466 123 l52
31 0 176 c0 213 8 236 82 260 44 14 48 18 48 48 l0 32 -295 0 -295 0 0 -34 0
-33 96 -7 c180 -12 194 -28 194 -214 0 -185 -21 -223 -147 -273 -50 -20 -76
-23 -193 -23 -144 0 -203 14 -290 69 -103 65 -195 210 -226 355 -22 101 -21
292 1 390 62 271 248 429 507 430 217 0 350 -94 413 -293 l23 -72 41 -3 41 -3
0 220 0 221 -40 0 c-27 0 -40 -4 -40 -14 0 -8 -8 -24 -18 -36 l-18 -22 -71 25
c-39 14 -105 33 -146 41 -92 19 -278 20 -363 1z"
        />
        <path
          d="M20 3395 l0 -35 48 0 c65 0 114 -26 129 -70 17 -50 17 -1040 0 -1090
-15 -44 -64 -70 -129 -70 -47 0 -48 -1 -48 -30 l0 -30 348 0 c300 0 359 3 438
19 280 58 458 208 530 446 26 84 26 313 1 404 -48 174 -161 313 -317 390 -166
81 -309 100 -742 101 l-258 0 0 -35z m695 -60 c218 -46 379 -204 432 -423 22
-91 21 -267 -1 -352 -55 -203 -203 -353 -401 -405 -33 -8 -101 -15 -152 -15
-116 0 -161 19 -195 83 -23 42 -23 43 -26 585 l-3 542 138 0 c83 0 166 -6 208
-15z"
        />
        <path
          d="M1454 3416 c-14 -38 0 -51 61 -57 77 -8 93 -15 110 -50 13 -24 15
-113 15 -563 0 -371 -3 -543 -11 -559 -15 -33 -46 -48 -116 -56 -54 -6 -58 -8
-61 -33 l-3 -28 570 0 c530 0 571 1 571 17 0 18 18 271 24 341 l4 42 -43 0
-42 0 -12 -73 c-7 -41 -25 -101 -40 -134 -30 -62 -61 -92 -120 -111 -46 -16
-446 -16 -491 -1 -55 20 -60 46 -60 324 l0 245 213 0 c195 0 215 -2 245 -20
37 -23 56 -61 66 -132 7 -48 7 -48 47 -48 l39 0 0 240 0 240 -38 0 -38 0 -12
-62 c-13 -68 -35 -106 -73 -125 -17 -9 -88 -13 -236 -13 l-213 0 0 276 0 275
263 -3 c251 -3 264 -4 310 -26 65 -33 90 -77 121 -214 6 -25 11 -28 47 -28
l41 0 -6 123 c-4 67 -9 146 -12 175 l-5 52 -555 0 c-474 0 -555 -2 -560 -14z"
        />
        <path
          d="M3880 3395 l0 -35 48 0 c65 0 114 -26 129 -70 9 -25 12 -176 12 -545
0 -369 -3 -520 -12 -545 -15 -44 -64 -70 -129 -70 -47 0 -48 -1 -48 -30 l0
-30 270 0 270 0 0 30 c0 29 -2 30 -42 31 -24 0 -59 5 -78 12 -66 23 -65 12
-65 602 0 401 3 537 12 558 16 34 66 57 128 57 45 0 45 0 45 35 l0 35 -270 0
-270 0 0 -35z"
        />
        <path
          d="M5994 3416 c-12 -31 1 -50 39 -59 23 -6 59 -29 92 -59 l55 -50 0
-504 c0 -546 -1 -554 -54 -589 -14 -10 -49 -20 -78 -24 -48 -5 -53 -8 -56 -33
l-3 -28 250 0 251 0 0 29 c0 28 -1 28 -66 34 -43 3 -76 12 -93 24 -56 42 -56
41 -59 516 -2 253 1 437 6 433 5 -3 186 -239 402 -526 l393 -520 69 0 68 0 0
603 c0 553 1 605 18 637 19 38 52 60 95 60 24 0 27 4 27 35 l0 35 -230 0 -230
0 0 -34 0 -33 63 -5 c80 -7 116 -24 141 -68 20 -34 21 -52 24 -507 2 -260 -1
-473 -5 -473 -4 1 -196 253 -427 560 l-418 560 -135 0 c-107 0 -135 -3 -139
-14z"
        />
        <path
          d="M7900 3397 c0 -28 4 -34 32 -42 20 -6 39 -22 51 -42 14 -25 347 -973
426 -1216 l13 -38 66 3 66 3 148 419 c81 230 149 420 151 423 4 3 77 -202 245
-684 l56 -163 66 0 65 0 179 558 c99 306 192 589 208 627 29 73 65 115 99 115
15 0 19 7 19 35 l0 35 -202 -2 -203 -3 0 -30 c0 -29 1 -30 63 -37 107 -12 135
-46 112 -136 -14 -58 -290 -922 -300 -941 -9 -16 -42 77 -216 601 -142 429
-141 427 -74 460 19 10 56 18 83 18 l47 0 0 35 0 35 -265 0 -265 0 0 -34 c0
-33 1 -34 52 -38 71 -7 95 -38 145 -186 l40 -117 -141 -397 c-77 -218 -144
-391 -147 -385 -17 30 -308 939 -314 979 -6 39 -3 49 17 73 20 23 35 29 81 33
l57 4 0 34 0 34 -230 0 -230 0 0 -33z"
        />
        <path
          d="M9870 3396 l0 -33 66 -6 c54 -4 71 -10 93 -31 l26 -26 0 -555 0 -555
-26 -26 c-22 -21 -39 -27 -93 -31 -65 -6 -66 -6 -66 -34 l0 -29 568 0 569 0
12 168 c17 246 18 232 -27 232 l-39 0 -16 -82 c-9 -46 -29 -107 -44 -137 -23
-45 -38 -60 -82 -83 l-53 -28 -225 0 c-244 0 -274 6 -293 56 -6 14 -10 138
-10 275 l0 250 219 -3 c204 -3 222 -4 248 -24 28 -21 46 -63 58 -136 5 -35 8
-38 40 -38 l35 0 0 240 0 240 -35 0 c-32 0 -35 -3 -40 -37 -14 -79 -26 -110
-51 -134 -25 -24 -28 -24 -250 -27 l-224 -3 0 275 0 276 243 0 c284 0 324 -7
379 -68 25 -28 40 -58 52 -108 10 -38 20 -75 22 -81 2 -8 20 -13 44 -13 l41 0
-5 78 c-3 42 -8 121 -12 175 l-6 97 -559 0 -559 0 0 -34z"
        />
        <path
          d="M11170 3396 l0 -33 66 -6 c54 -4 71 -10 93 -31 l26 -26 0 -555 0
-555 -26 -26 c-22 -21 -39 -27 -93 -31 -65 -6 -66 -6 -66 -34 l0 -29 568 0
569 0 12 168 c17 246 18 232 -27 232 l-39 0 -16 -82 c-9 -46 -29 -107 -44
-137 -23 -45 -38 -60 -82 -83 l-53 -28 -225 0 c-244 0 -274 6 -293 56 -6 14
-10 138 -10 275 l0 250 219 -3 c204 -3 222 -5 247 -24 28 -20 47 -65 59 -136
5 -35 8 -38 40 -38 l35 0 0 240 0 240 -35 0 c-32 0 -35 -3 -40 -37 -14 -79
-26 -110 -51 -134 -25 -24 -28 -24 -250 -27 l-224 -3 0 275 0 276 243 0 c284
0 324 -7 379 -68 25 -28 40 -58 52 -108 10 -38 20 -75 22 -81 2 -8 20 -13 44
-13 l41 0 -5 78 c-3 42 -8 121 -12 175 l-6 97 -559 0 -559 0 0 -34z"
        />
        <path
          d="M12470 3396 l0 -33 66 -6 c54 -4 71 -10 93 -31 l26 -26 2 -543 c2
-421 0 -548 -10 -567 -17 -34 -73 -60 -129 -60 -47 0 -48 -1 -48 -30 l0 -30
270 0 270 0 0 30 c0 28 -2 30 -40 30 -52 0 -103 21 -124 51 -13 20 -16 55 -16
224 l0 201 59 52 c33 28 64 52 69 52 8 0 346 -391 410 -474 25 -32 29 -68 9
-85 -8 -6 -38 -14 -68 -18 -49 -7 -54 -11 -57 -36 l-3 -27 255 0 256 0 0 29
c0 22 -6 30 -23 35 -13 3 -42 19 -64 35 -41 30 -578 644 -578 661 0 9 431 394
510 456 46 36 117 74 140 74 11 0 15 10 15 35 l0 35 -242 -2 -243 -3 0 -29 c0
-28 3 -30 57 -41 66 -13 88 -28 88 -61 0 -35 -46 -81 -330 -335 l-255 -228 -3
272 c-2 193 1 280 10 301 14 35 61 56 123 56 45 0 45 0 45 35 l0 35 -270 0
-270 0 0 -34z"
        />
        <path
          d="M355 1426 c-120 -34 -200 -86 -261 -169 -107 -145 -113 -363 -14
-520 l30 -47 63 40 c34 22 69 44 77 49 13 8 12 12 -3 29 -10 11 -26 42 -37 68
-61 162 -12 299 134 374 64 32 76 35 155 34 72 0 96 -5 149 -29 109 -49 166
-138 166 -260 0 -157 -93 -260 -354 -390 -132 -66 -220 -126 -280 -192 -86
-94 -140 -226 -140 -340 l0 -53 475 0 475 0 0 85 0 85 -370 2 -369 3 18 41
c41 90 126 157 346 272 211 109 304 198 352 334 27 80 25 253 -6 331 -43 110
-128 191 -250 238 -72 28 -278 37 -356 15z"
        />
        <path
          d="M1561 1424 c-123 -33 -247 -134 -309 -251 -114 -216 -135 -526 -51
-776 63 -189 176 -312 340 -368 85 -29 236 -31 327 -4 206 60 348 250 392 528
18 110 8 339 -19 441 -60 227 -200 382 -388 431 -72 19 -221 18 -292 -1z m272
-160 c104 -35 197 -156 239 -311 30 -111 30 -344 0 -455 -28 -102 -64 -171
-123 -234 -70 -73 -131 -99 -239 -99 -109 0 -169 25 -240 101 -100 105 -144
248 -143 459 1 152 18 244 67 347 84 178 259 254 439 192z"
        />
        <path
          d="M2791 1424 c-79 -21 -149 -62 -212 -124 -119 -118 -157 -295 -100
-463 15 -42 56 -121 76 -145 3 -3 145 88 145 93 0 1 -14 30 -31 64 -56 111
-50 228 14 313 46 60 101 95 181 115 59 14 79 15 138 5 83 -14 120 -32 171
-81 56 -53 79 -104 85 -187 11 -170 -62 -257 -343 -404 -66 -35 -146 -81 -177
-102 -152 -102 -234 -238 -253 -422 l-7 -66 481 0 481 0 0 85 0 85 -371 0
-370 0 7 23 c28 93 129 179 324 277 268 135 372 249 401 442 29 195 -62 380
-224 457 -34 16 -86 34 -115 40 -73 15 -236 13 -301 -5z"
        />
        <path
          d="M4019 1422 c-90 -30 -157 -72 -217 -137 -69 -74 -112 -152 -148 -270
-26 -85 -28 -102 -28 -285 -1 -160 2 -208 18 -265 24 -92 77 -206 124 -267
141 -187 418 -248 640 -143 223 106 349 388 328 735 -19 312 -144 528 -357
617 -86 36 -273 43 -360 15z m308 -172 c151 -74 233 -261 233 -529 0 -144 -18
-240 -64 -338 -43 -90 -102 -155 -175 -190 -49 -24 -67 -28 -146 -28 -78 1
-97 4 -144 29 -147 76 -226 236 -238 482 -15 317 104 553 304 604 65 16 161 4
230 -30z"
        />
      </g>
    </svg>
  )
}
