import React from "react"
import { TIcon } from "../../definitions/types/TIcon"

export default function IconRewind({
  size = 24,
  className,
  style,
  onClick,
}: TIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={`${className}`}
      style={style}
      stroke="currentColor"
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
      />
    </svg>
  )
}
