import React from "react"
import IconDoubleChevronRight from "../Icons/IconDoubleChevronRight"
import WorkContainer from "./WorkContainer"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function NDW() {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "design-week" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <WorkContainer>
      <div className="">
        <div className="flex items-end">
          <div className="text-xl font-sans">Nashville Design Week</div>
        </div>
        <div className="text-xs mb-4 font-mono text-gray-500">
          <a
            href="https://nashvilledesignweek.org"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block transform ease-in-out duration-300 hover:translate-x-1 hover:text-gray-50"
          >
            nashvilledesignweek.org
          </a>
        </div>
        <div className="text-base font-sans text-gray-500">
          Director of Operations, Board Member
        </div>
        <div className="font-mono text-gray-500 text-sm">2019 - Present</div>
        <div className="font-sans text-sm mt-6">
          <p>
            Nashville Design Week is a 501(c)(3) non-profit organization that
            hosts a week-long series of events each year in the fall. The events
            bring all facets of Nashville's creative and design communities
            together to showcase their work, meet each other, and invite the
            public to learn about their industries.
          </p>
          <p>
            As Director of Operations, I handle the finances, conduct weekly
            meetings with the other directors, oversee 40+ volunteers (it's an
            entirely volunteer organization), and help put on 20-30 events
            during Design Week.
          </p>
          <p>
            I also manage the website and pitch in with design work where
            needed.
          </p>
        </div>
        <Link
          to="/ndw"
          className="flex items-center font-mono text-gray-500 hover:text-gray-50 text-sm"
        >
          <div>More about Nashville Design Week</div>
          <IconDoubleChevronRight size={16} className="ml-2" />
        </Link>
      </div>
      <div className="flex justify-center">
        <div className="relative">
          <Img
            className="z-30 w-72 h-auto rounded-md shadow-2xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[0].node.childImageSharp.fluid}
          />
          <Img
            className="absolute -top-4 left-16 sm:left-36 z-20 w-60 h-auto rounded-md shadow-2xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[1].node.childImageSharp.fluid}
          />
          <Img
            className="absolute -top-8 sm:left-24 z-10 w-52 h-auto rounded-md shadow-2xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[2].node.childImageSharp.fluid}
          />
        </div>
      </div>
    </WorkContainer>
  )
}
