import React from "react"
import { TIcon } from "../../definitions/types/TIcon"

export default function IconDoubleChevronRight({
  size = 24,
  className,
  style,
  onClick,
}: TIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={`${className}`}
      style={style}
      stroke="currentColor"
      viewBox="0 0 24 24"
      onClick={onClick}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 5l7 7-7 7M5 5l7 7-7 7"
      />
    </svg>
  )
}
