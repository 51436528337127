import sample from "lodash/sample"
import { Colors } from "../definitions/Colors"
import { Shades } from "../definitions/Shades"

export function generateColorClass(color: string | undefined, shade: string | undefined): string {
  return `bg-${color}-${shade}`
}

export function randomColorClass(shade: string | undefined): string {
  return `bg-${sample(Colors)}-${shade}`
}

export function randomColorShadeClass(color: string | undefined): string {
  return `bg-${color}-${sample(Shades)}`
}