import { AnimatePresence, motion, Variants } from "framer-motion"
import React, { useState } from "react"
import BFLogo from "../Logos/BFLogo"
import CohubLogo from "../Logos/CohubLogo"
import NDWLogo from "../Logos/NDWLogo"
import PreferrLogo from "../Logos/PreferrLogo"
import Cohub from "../Work/Cohub"
import BillFixers from "../Work/BillFixers"
import Preferr from "../Work/Preferr"
import NDW from "../Work/NDW"

interface IProps {
  variants: Variants
}

type TActiveProject = "cohub" | "billfixers" | "preferr" | "ndw" | undefined

export default function WorkExperience({ variants }: IProps) {
  const [activeProject, setActiveProject] = useState<TActiveProject>("cohub")

  return (
    <motion.div
      variants={variants}
      className="mb-64"
      style={{ minHeight: "700px" }}
    >
      <motion.div
        id="work"
        variants={variants}
        className="mt-28 mb-12 font-serif text-gray-700 text-5xl sm:-ml-12 font-semibold"
        style={{ scrollMarginTop: 200 }}
      >
        Work
      </motion.div>
      <motion.div
        variants={variants}
        className="flex flex-col sm:flex-row items-center justify-evenly w-full"
      >
        <div
          onClick={() => setActiveProject("cohub")}
          className={`flex items-center mb-4 sm:mb-0 h-20 py-2 px-4 rounded-lg bg-gray-800 hover:bg-coolGray-800 transition ease-in-out duration-100 opacity-80 hover:opacity-100 cursor-pointer ${
            activeProject === "cohub" ? "bg-coolGray-800 opacity-100" : ""
          }`}
        >
          <CohubLogo size={150} />
        </div>
        <div
          onClick={() => setActiveProject("billfixers")}
          className={`flex items-center mb-4 sm:mb-0 h-20 py-2 px-4 rounded-lg bg-gray-800 hover:bg-coolGray-800 transition ease-in-out duration-100 opacity-80 hover:opacity-100 cursor-pointer ${
            activeProject === "billfixers" ? "bg-coolGray-800 opacity-100" : ""
          }`}
        >
          <BFLogo size={150} />
        </div>
        <div
          onClick={() => setActiveProject("preferr")}
          className={`flex items-center mb-4 sm:mb-0 h-20 py-2 px-4 rounded-lg bg-gray-800 hover:bg-coolGray-800 transition ease-in-out duration-100 opacity-80 hover:opacity-100 cursor-pointer ${
            activeProject === "preferr" ? "bg-coolGray-800 opacity-100" : ""
          }`}
        >
          <PreferrLogo size={150} />
        </div>
        <div
          onClick={() => setActiveProject("ndw")}
          className={`flex items-center mb-4 sm:mb-0 h-20 py-2 px-4 rounded-lg bg-gray-800 hover:bg-coolGray-800 transition ease-in-out duration-100 opacity-80 hover:opacity-100 cursor-pointer ${
            activeProject === "ndw" ? "bg-coolGray-800 opacity-100" : ""
          }`}
        >
          <NDWLogo size={150} />
        </div>
      </motion.div>
      <AnimatePresence exitBeforeEnter>
        {activeProject === "cohub" && <Cohub key="cohub" />}
        {activeProject === "billfixers" && <BillFixers key="billfixers" />}
        {activeProject === "preferr" && <Preferr key="preferr" />}
        {activeProject === "ndw" && <NDW key="ndw" />}
        {/* {activeProjectContent()} */}
      </AnimatePresence>
    </motion.div>
  )
}
