import { motion, Variants } from "framer-motion"
import React from "react"
import IconMail from "../Icons/IconMail"
import LinkedInLogo from "../Logos/LinkedInLogo"
import TwitterLogo from "../Logos/TwitterLogo"

interface IProps {
  variants: Variants
}

export default function Contact({ variants }: IProps) {
  return (
    <motion.div variants={variants} className="mb-64">
      <motion.div
        layout
        id="contact"
        variants={variants}
        className="mt-28 mb-12 font-serif text-gray-700 text-5xl sm:-ml-12 font-semibold"
      >
        Contact
      </motion.div>
      <motion.div
        variants={variants}
        className="flex flex-col sm:flex-row sm:items-center sm:justify-evenly"
      >
        <a
          href="mailto:eroche91@gmail.com"
          className="flex items-center text-gray-400 hover:text-gray-50 text-base font-mono mb-4"
        >
          <IconMail size={32} />
          <div className="ml-2">eroche91@gmail.com</div>
        </a>
        <a
          href="https://twitter.com/Elliott_Roche"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-gray-400 hover:text-gray-50 text-base font-mono mb-4"
        >
          <TwitterLogo size={32} />
          <div className="ml-2">@Elliott_Roche</div>
        </a>
        <a
          href="https://www.linkedin.com/in/elliott-roche-661070ba/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-gray-400 hover:text-gray-50 text-base font-mono mb-4"
        >
          <LinkedInLogo size={32} />
          <div className="ml-2">Elliott Roche</div>
        </a>
      </motion.div>
    </motion.div>
  )
}
