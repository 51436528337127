import React from "react"

interface IProps {
  size?: number
  className?: string
}

export default function BFLogo({ size = 202, className }: IProps) {
  return (
    <svg
      width={size}
      height={size * (62 / size)}
      viewBox="0 0 202 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M60.8458 27.6913C58.6434 27.6913 56.9533 28.4039 56.2362 28.7093C56.185 28.7603 56.0826 28.7603 56.0314 28.8112V14.1002C56.0314 13.5402 55.5192 13.0312 54.9558 13.0312H51.9852C51.4218 13.0312 50.9097 13.5402 50.9097 14.1002V47.5944C50.9097 48.1543 51.4218 48.6634 51.9852 48.6634H53.5217C53.9827 48.6634 54.3412 48.358 54.5461 47.7471L54.9046 46.4236C55.4168 46.9836 57.7728 49.1724 61.3067 49.1724C66.6845 49.1724 70.9355 44.4893 70.9355 38.4318C70.9355 32.4253 66.5309 27.6913 60.8458 27.6913ZM66.0187 38.4318C66.0187 41.9442 63.7139 44.5911 60.6921 44.5911C57.4655 44.5911 56.0826 41.8424 55.9289 41.6387V33.4433C56.185 33.2906 57.8752 32.3235 60.2824 32.3235C63.5603 32.2726 66.0187 34.9195 66.0187 38.4318Z"
        fill="#F9FAFB"
      />
      <path
        d="M78.8743 28.2003H75.955C75.3916 28.2003 74.8794 28.7093 74.8794 29.2693V47.5944C74.8794 48.1543 75.3916 48.6634 75.955 48.6634H78.8743C79.4377 48.6634 79.9499 48.1543 79.9499 47.5944V29.2693C79.8987 28.7093 79.4377 28.2003 78.8743 28.2003Z"
        fill="#F9FAFB"
      />
      <path
        d="M77.3378 14.0493C75.6476 14.0493 74.3672 15.4236 74.3672 17.1034C74.3672 18.7832 75.6988 20.1067 77.3378 20.1067C79.0279 20.1067 80.4108 18.7323 80.4108 17.1034C80.4108 15.4236 79.0792 14.0493 77.3378 14.0493Z"
        fill="#F9FAFB"
      />
      <path
        d="M89.2713 13.0312H86.3519C85.7885 13.0312 85.2764 13.5402 85.2764 14.1002V47.5944C85.2764 48.1543 85.7885 48.6634 86.3519 48.6634H89.2713C89.8347 48.6634 90.3469 48.1543 90.3469 47.5944V14.1002C90.3469 13.5402 89.8347 13.0312 89.2713 13.0312Z"
        fill="#F9FAFB"
      />
      <path
        d="M99.7195 13.0312H96.8002C96.2368 13.0312 95.7246 13.5402 95.7246 14.1002V47.5944C95.7246 48.1543 96.2368 48.6634 96.8002 48.6634H99.7195C100.283 48.6634 100.795 48.1543 100.795 47.5944V14.1002C100.795 13.5402 100.283 13.0312 99.7195 13.0312Z"
        fill="#F9FAFB"
      />
      <path
        d="M123.843 28.2003H112.319V22.041C112.319 18.4269 114.521 17.1543 116.468 17.1543C118.721 17.1543 121.436 18.5796 121.743 18.7832C122.153 18.9869 122.46 19.0378 122.665 18.9869C122.87 18.9359 123.023 18.7832 123.177 18.4778L124.457 16.1872C124.611 15.9836 124.662 15.6781 124.56 15.3727C124.509 15.1182 124.355 14.9146 124.15 14.7619C123.792 14.5074 120.309 12.4713 116.468 12.4713C110.936 12.4713 107.197 16.289 107.197 21.9901V28.1494H104.892C104.278 28.1494 103.817 28.6075 103.817 29.2184V31.5599C103.817 32.1199 104.278 32.6289 104.892 32.6289H107.197V47.5944C107.197 48.1543 107.709 48.6634 108.273 48.6634H110.834C111.551 48.6634 112.37 48.4089 112.37 47.5944V32.578H119.899V47.5435C119.899 48.1034 120.411 48.6125 120.975 48.6125H123.894C124.457 48.6125 124.97 48.1034 124.97 47.5435V29.2184C124.918 28.7093 124.406 28.2003 123.843 28.2003Z"
        fill="#F9FAFB"
      />
      <path
        d="M141.052 38.1773L147.403 29.6765C147.659 29.3202 147.761 28.9639 147.608 28.6584C147.454 28.4039 147.147 28.2003 146.737 28.2003H143.408C142.896 28.2003 142.435 28.4039 142.179 28.8112L138.03 34.5632L133.984 28.8112C133.625 28.3021 133.062 28.2003 132.704 28.2003H129.374C128.965 28.2003 128.657 28.353 128.504 28.6584C128.35 28.9639 128.401 29.3711 128.657 29.6765L135.008 38.1773L128.452 47.1363C128.196 47.4926 128.145 47.8998 128.299 48.2052C128.452 48.4598 128.76 48.6125 129.17 48.6125H132.601C133.062 48.6125 133.318 48.3579 133.472 48.1543L137.979 41.7405L142.435 47.9507C142.742 48.3579 143.152 48.6125 143.562 48.6125H146.788C147.198 48.6125 147.505 48.4598 147.608 48.2052C147.761 47.8998 147.71 47.5435 147.454 47.1872L141.052 38.1773Z"
        fill="#F9FAFB"
      />
      <path
        d="M159.285 27.6913C153.446 27.6913 148.99 32.2726 148.99 38.3809C148.99 41.2824 150.015 43.9803 151.807 45.9146C153.702 47.9507 156.365 49.0706 159.285 49.0706C162.153 49.0706 165.021 48.1034 166.865 46.5254C167.121 46.2709 167.275 46.0673 167.326 45.8128C167.326 45.5074 167.172 45.2529 167.019 45.0492L165.585 43.1149C165.124 42.555 164.714 42.8095 164.099 43.1658C163.28 43.7258 161.538 44.642 159.643 44.642C156.314 44.642 154.061 41.9442 153.907 39.399H167.633C168.145 39.399 168.606 38.9918 168.658 38.4827C168.709 38.1264 168.76 37.4647 168.76 37.1084C168.709 31.7635 164.663 27.6913 159.285 27.6913ZM154.112 36.0903C154.47 33.596 156.57 31.7635 159.182 31.7635C161.538 31.7635 163.587 33.6979 163.792 36.0903H154.112Z"
        fill="#F9FAFB"
      />
      <path
        d="M182.333 27.6913C178.85 27.6913 176.955 29.8292 176.391 30.5419L175.879 29.0148C175.777 28.6075 175.367 28.2512 174.906 28.2512H173.421C172.857 28.2512 172.345 28.7602 172.345 29.3202V47.6453C172.345 48.2052 172.857 48.7143 173.421 48.7143H175.879C176.647 48.7143 177.364 48.6125 177.364 47.6453V34.7668C177.518 34.5123 178.901 32.3744 181.872 32.3744C182.537 32.3744 183.254 32.578 183.562 32.7307C184.125 32.9852 184.637 32.7816 184.893 32.2217L186.123 29.7274C186.276 29.3711 186.225 29.0657 186.071 28.8112C185.508 27.9967 183.767 27.6913 182.333 27.6913Z"
        fill="#F9FAFB"
      />
      <path
        d="M195.188 36.0903C193.242 35.2758 191.961 34.7159 191.961 33.4433C191.961 32.5271 192.781 32.018 194.164 32.018C196.059 32.018 198.261 33.0361 198.312 33.087C198.722 33.2906 199.49 33.1379 199.746 32.6289L200.668 30.8982C201.027 30.3382 200.719 29.6256 200.207 29.3202C199.644 28.9639 197.493 27.7422 194.164 27.7422C191.91 27.7422 190.066 28.4039 188.786 29.6256C187.71 30.6437 187.096 32.018 187.096 33.4433C187.096 37.2102 190.425 39.0427 192.934 40.1625C195.7 41.3842 196.776 42.046 196.776 43.3694C196.776 44.3875 195.905 44.9983 194.369 44.9983C191.91 44.9983 189.708 43.7258 189.657 43.6749C189.349 43.5222 189.042 43.4712 188.786 43.5222C188.53 43.5731 188.376 43.7258 188.274 43.9294L187.198 45.9146C186.993 46.3727 187.198 46.8818 187.505 47.0854C188.274 47.6962 190.527 49.1724 194.266 49.1724C198.62 49.1724 201.897 46.5763 201.897 43.1658C202 39.3481 199.029 37.7701 195.188 36.0903Z"
        fill="#F9FAFB"
      />
      <path
        d="M36.6202 21.9901C35.2885 18.7833 32.318 16.8489 28.8352 16.8489H25.1988L27.8109 14.2529C30.2693 11.8095 30.9351 8.34811 29.6547 5.14121C28.2718 1.98522 25.3012 0 21.8697 0H2.61207C1.17799 0 0 1.17077 0 2.59606V19.445C0 20.8703 1.17799 22.041 2.61207 22.041H12.497H19.9234H28.8352C30.7814 22.041 31.5497 23.5172 31.7546 23.9754C31.9594 24.4335 32.4716 26.0115 31.0887 27.3859L5.27536 53.041V36.6502L16.5431 25.4516H9.11663L0.768256 33.6979C0.256085 34.2069 0 34.8686 0 35.5304V59.353C0 60.422 0.614605 61.3383 1.63895 61.7455C1.94625 61.8982 2.30477 61.9491 2.66329 61.9491C3.38032 61.9491 4.04615 61.6946 4.5071 61.1855L34.8276 31.0509C37.2348 28.6585 37.9518 25.197 36.6202 21.9901ZM24.072 10.5878L17.7723 16.8489H5.22414V5.19212H21.8185C23.7647 5.19212 24.533 6.66831 24.7378 7.12644C24.9427 7.58456 25.4549 9.21346 24.072 10.5878Z"
        fill="#2CDFAF"
      />
    </svg>
  )
}
