import React from "react"

interface IProps {
  size?: number
  className?: string
}

export default function ShoddyDesignLogo({ size = 200, className }: IProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="512" height="512" fill="#161616" />
      <path
        d="M98.6641 120.668C98.6641 117.973 97.9492 116.051 96.5195 114.902C95.1133 113.754 93.1328 113.18 90.5781 113.18H80.4883V128.438H90.5781C93.1328 128.438 95.1133 127.816 96.5195 126.574C97.9492 125.332 98.6641 123.363 98.6641 120.668ZM109.387 120.598C109.387 126.715 107.84 131.039 104.746 133.57C101.652 136.102 97.2344 137.367 91.4922 137.367H80.4883V156H69.7305V104.18H92.3008C97.5039 104.18 101.652 105.516 104.746 108.188C107.84 110.859 109.387 114.996 109.387 120.598ZM69.4844 187.18H80.4883V219.031C80.4883 222.594 80.9102 225.195 81.7539 226.836C83.0664 229.742 85.9258 231.195 90.332 231.195C94.7148 231.195 97.5625 229.742 98.875 226.836C99.7188 225.195 100.141 222.594 100.141 219.031V187.18H111.145V219.031C111.145 224.539 110.289 228.828 108.578 231.898C105.391 237.523 99.3086 240.336 90.332 240.336C81.3555 240.336 75.2617 237.523 72.0508 231.898C70.3398 228.828 69.4844 224.539 69.4844 219.031V187.18ZM69.4844 270.18H80.3125V312.684H105.977V322H69.4844V270.18ZM69.4844 353.18H80.3125V395.684H105.977V405H69.4844V353.18Z"
        fill="#F9F9F9"
      />
    </svg>
  )
}
