import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import IconDoubleChevronRight from "../Icons/IconDoubleChevronRight"
import WorkContainer from "./WorkContainer"

export default function BillFixers() {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "billfixers" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <WorkContainer>
      <div className="">
        <div className="flex items-end">
          <div className="text-xl font-sans">BillFixers</div>
        </div>
        <div className="text-xs mb-4 font-mono text-gray-500">
          <a
            href="https://billfixers.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block transform ease-in-out duration-300 hover:translate-x-1 hover:text-gray-50"
          >
            billfixers.com
          </a>
        </div>
        <div className="text-base font-sans text-gray-500">CTO</div>
        <div className="font-mono text-gray-500 text-sm">2016 - Present</div>
        <div className="font-sans text-sm mt-6">
          <p>
            BillFixers is a bill negotiation service started by some old friends
            of mine. They negotiate better rates on any kind of recurring bill,
            from telecom bills to waste management bills, and anything in
            between.
          </p>
          <p>
            I've worked for them as their primary developer in a freelance
            capacity since just after they launched, spending nights and
            weekends working on the platform.
          </p>
          <p>
            I built the backend and frontend of BillFixers, using Ruby on Rails,
            GraphQL, React, and TypeScript. I also helped design the frontend
            app, which has been used by 20,000+ customers since 2015.
          </p>
        </div>
        <Link
          to="/billfixers"
          className="flex items-center font-mono text-gray-500 hover:text-gray-50 text-sm"
        >
          <div>More about BillFixers</div>
          <IconDoubleChevronRight size={16} className="ml-2" />
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="relative">
          <Img
            className="z-30 absolute top-4 left-12 sm:left-36 w-60 h-auto rounded-md shadow-2xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[1].node.childImageSharp.fluid}
          />
          <Img
            className="z-10 w-80 h-auto rounded-md shadow-2xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[0].node.childImageSharp.fluid}
          />
          {/* <Img
            className="absolute -top-8 sm:left-24 z-10 w-52 h-auto rounded-sm transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[2].node.childImageSharp.fluid}
          /> */}
        </div>
      </div>
    </WorkContainer>
  )
}
