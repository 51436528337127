import { motion } from "framer-motion"
import React, { ReactNode, ReactNodeArray } from "react"

interface IProps {
  children: ReactNode | ReactNodeArray
}
export default function WorkContainer({ children }: IProps) {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.5 }}
      className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-24 text-gray-50 z-0"
    >
      {children}
    </motion.div>
  )
}
