import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { motion, Variants } from "framer-motion"
import Container from "../components/Container"
import { AnimatingGrid } from "../components/AnimatingGrid/AnimatingGrid"
import SideProjects from "../components/Layout/SideProjects"
import WorkExperience from "../components/Layout/WorkExperience"
import Contact from "../components/Layout/Contact"

const section: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
}

const IndexPage: React.FC = ({ data }: any) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <motion.div
          layout
          id="hi"
          variants={section}
          className="mt-24 mb-4 font-serif text-gray-700 text-5xl -ml-6 sm:-ml-12 font-semibold"
        >
          Hi!
        </motion.div>
        <motion.div
          variants={section}
          className="flex flex-col sm:flex-row items-center"
        >
          <motion.div
            variants={section}
            className="text-2xl sm:text-3xl text-gray-50 font-sans font-light w-full mr-8 mb-8 sm:mb-0"
          >
            <div className="mb-4">I'm Elliott.</div>
            <div className="mb-4">A full stack developer and designer.</div>
            <div className="text-lg mb-4">
              I've built backends and frontends, designed engaging user
              interfaces, and helped start a handful of small companies.
            </div>
            <div className="text-lg">I enjoy being a jack of all trades.</div>
          </motion.div>
          <div className="flex justify-center items-center w-full">
            <AnimatingGrid boxCount={49} />
          </div>
        </motion.div>
        <WorkExperience variants={section} />
        <SideProjects variants={section} />
        <Contact variants={section} />
      </Container>
    </Layout>
  )
}

export default IndexPage
