import { motion, Variants } from "framer-motion"
import React, { useEffect, useState } from "react"
import sample from "lodash/sample"
import {
  generateColorClass,
  randomColorShadeClass,
} from "../../utils/tailwindColors"
import { Colors } from "../../definitions/Colors"
import { Shades } from "../../definitions/Shades"

const box: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
}

interface IProps {
  color?: string
  onClick?: () => void
  playSpeed?: number
}

export default function Box({ color, onClick, playSpeed = 5000 }: IProps) {
  const [bgColor, setBgColor] = useState<string | undefined>()

  const randomizeBgColor = (color?: string) => {
    if (color) {
      const randomColor = randomColorShadeClass(color)
      setBgColor(randomColor)
    } else {
      const randomColor = generateColorClass(sample(Colors), sample(Shades))
      setBgColor(randomColor)
    }
  }

  useEffect(() => {
    let colorChangeInterval: NodeJS.Timer
    randomizeBgColor()

    if (color) {
      randomizeBgColor(color)
      colorChangeInterval = setInterval(
        () => randomizeBgColor(color),
        playSpeed
      )
    } else {
      colorChangeInterval = setInterval(() => randomizeBgColor(), playSpeed)
    }

    return () => {
      clearInterval(colorChangeInterval)
    }
  }, [color, playSpeed])

  return (
    <motion.div
      variants={box}
      className={`w-6 h-6 rounded-md sm:w-8 sm:h-8 sm:rounded-lg transition duration-700 ${
        onClick ? "cursor-pointer" : ""
      } ${bgColor}`}
      // style={{ backgroundColor: boxColor }}
      onClick={onClick}
    />
  )
}
