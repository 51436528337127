import React from "react"
import { TIcon } from "../../definitions/types/TIcon"

export default function IconFastForward({
  size = 24,
  className,
  style,
  onClick,
}: TIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={`${className}`}
      style={style}
      stroke="currentColor"
      viewBox="0 0 24 24"
      onClick={onClick}
      fill="none"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
      />
    </svg>
  )
}
