import React, { ReactNodeArray, useEffect, useState } from "react"
import { motion, Variants } from "framer-motion"
import Box from "./Box"
import times from "lodash/times"
import sample from "lodash/sample"
import { Colors } from "../../definitions/Colors"
import IconFastForward from "../Icons/IconFastForward"
import IconRewind from "../Icons/IconRewind"

interface IProps {
  boxCount: number
}

const container: Variants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.03,
    },
  },
  hidden: {
    opacity: 0,
  },
}

export const AnimatingGrid = ({ boxCount }: IProps) => {
  const [playSpeed, setPlaySpeed] = useState<number>(5000)
  const [colorSync, setColorSync] = useState(true)
  const [boxes, setBoxes] = useState<ReactNodeArray>(
    times(boxCount, idx => <Box playSpeed={playSpeed} key={idx} />)
  )

  useEffect(() => {
    let randomColorInterval: NodeJS.Timer
    let randomColor: string | undefined = sample(Colors)

    if (colorSync) {
      setBoxes(
        times(boxCount, idx => (
          <Box playSpeed={playSpeed} key={idx} color={randomColor} />
        ))
      )

      randomColorInterval = setInterval(() => {
        randomColor = sample(Colors)
        setBoxes(
          times(boxCount, idx => (
            <Box playSpeed={playSpeed} key={idx} color={randomColor} />
          ))
        )
      }, playSpeed)
    } else {
      setBoxes(times(boxCount, idx => <Box playSpeed={playSpeed} key={idx} />))
    }

    return () => {
      clearInterval(randomColorInterval)
    }
  }, [boxCount, colorSync, playSpeed])

  const rewind = () => {
    if (playSpeed < 15000) {
      const nextSpeed = playSpeed + 500
      setPlaySpeed(nextSpeed)
    }
  }

  const fastForward = () => {
    if (playSpeed > 500) {
      const nextSpeed = playSpeed - 500
      setPlaySpeed(nextSpeed)
    }
  }

  return (
    <div className="flex flex-col">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={container}
        className="grid grid-cols-7 grid-rows-7 gap-4"
      >
        {boxes}
      </motion.div>
      <div className="flex justify-between items-center mt-4 w-full">
        <IconRewind
          size={30}
          className="text-gray-700 cursor-pointer hover:text-gray-200 transition ease-in-out duration-100"
          onClick={rewind}
        />
        <button
          type="button"
          className="text-sm font-mono text-gray-700 hover:text-gray-200 transition ease-in-out duration-100 outline-none focus:outline-none"
          onClick={() => setColorSync(!colorSync)}
        >
          {colorSync === false ? "Synchronize" : "Randomize"}
        </button>
        <IconFastForward
          size={30}
          className="text-gray-700 cursor-pointer hover:text-gray-200 transition ease-in-out duration-100"
          onClick={fastForward}
        />
      </div>
    </div>
  )
}
