import { motion, Variants } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment } from "react"
import ShoddyDesignLogo from "../Logos/ShoddyDesignLogo"
import TrainSpotterLogo from "../Logos/TrainSpotterLogo"
import Img from "gatsby-image"

interface IProps {
  variants: Variants
}

export default function SideProjects({ variants }: IProps) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "subrecs-logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <motion.div variants={variants} className="mb-64">
      <motion.div
        layout
        id="side-projects"
        variants={variants}
        className="mt-28 mb-12 font-serif text-gray-700 text-5xl sm:-ml-12 font-semibold"
        style={{ scrollMarginTop: 200 }}
      >
        Side Projects
      </motion.div>
      <motion.div
        variants={variants}
        className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-evenly w-full"
      >
        <a
          href="https://cohub.com/train-spotter"
          target="_blank"
          rel="noopener noreferrer"
          // onClick={() => setActiveProject("cohub")}
          className={`w-48 flex flex-col items-center mb-4 ml-4 sm:ml-0 sm:mb-0 h-20 py-2 px-4 rounded-lg bg-gray-800 hover:bg-coolGray-800 transition ease-in-out duration-100 opacity-80 hover:opacity-100 cursor-pointer`}
        >
          <TrainSpotterLogo size={150} className="mb-2" />
          <div className="font-mono text-xs">Train Spotter</div>
        </a>
        <a
          href="https://shoddy.design"
          target="_blank"
          rel="noopener noreferrer"
          // onClick={() => setActiveProject("cohub")}
          className={`w-48 flex flex-col items-center mb-4 ml-12 sm:ml-0 sm:mb-0 h-20 py-2 px-4 rounded-lg bg-gray-800 hover:bg-coolGray-800 transition ease-in-out duration-100 opacity-80 hover:opacity-100 cursor-pointer`}
        >
          <ShoddyDesignLogo size={75} className="mb-2" />
          <div className="font-mono text-xs">Shoddy Design</div>
        </a>
        <a
          href="https://subrecs.app/"
          target="_blank"
          rel="noopener noreferrer"
          // onClick={() => setActiveProject("cohub")}
          className={`w-48 flex flex-col items-center mb-4 ml-20 sm:ml-0 sm:mb-0 h-20 py-2 px-4 rounded-lg bg-gray-800 hover:bg-coolGray-800 transition ease-in-out duration-100 opacity-80 hover:opacity-100 cursor-pointer`}
        >
          <Img
            fluid={data.file.childImageSharp.fluid}
            className="h-32 w-24 mb-2 rounded-md"
          />
          <div className="font-mono text-xs">Subrecs</div>
        </a>
      </motion.div>
    </motion.div>
  )
}
