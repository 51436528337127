import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import IconDoubleChevronRight from "../Icons/IconDoubleChevronRight"
import WorkContainer from "./WorkContainer"
import Img from "gatsby-image"

export default function Cohub() {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "cohub" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <WorkContainer>
      <div className="">
        <div className="flex items-end">
          <div className="text-xl font-sans">Cohub</div>
        </div>
        <div className="text-xs mb-4 font-mono text-gray-500">
          <a
            href="https://cohub.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block transform ease-in-out duration-300 hover:translate-x-1 hover:text-gray-50"
          >
            cohub.com
          </a>
        </div>
        <div className="text-base font-sans text-gray-500">Partner</div>
        <div className="font-mono text-gray-500 text-sm">2014 - Present</div>
        <div className="font-sans text-sm mt-6">
          <p>
            Cohub began as software that provided small companies with the same
            tools that big companies use: advanced inventory management,
            omnichannel sales, worldwide shipping from dozens of carriers,
            detailed analytics and reporting, and much more.
          </p>
          <p>
            I worked primarily as a developer and the lead designer, building
            features in Ruby on Rails, React, GraphQL, and TypeScript. I also
            created and implemented Cohub's design system.
          </p>
          <p>
            But I did a lot more than just design and development. I was one of
            two primary salesman, met with customers to help run business
            audits, and was involved in hiring our engineering team. I was also
            our only product manager and I planned and managed new and existing
            features and kept feature development on track.
          </p>
          <p>
            Cohub has changed a lot due to the impact of COVID-19, and went from
            a team of 10 to a team of 3 in a matter of months. We pivoted to
            building Shopify apps, and our first one is an app for counting
            inventory.
          </p>
        </div>
        <Link
          to="/cohub"
          className="flex items-center font-mono text-gray-500 hover:text-gray-50 text-sm"
        >
          <div>More about Cohub</div>
          <IconDoubleChevronRight size={16} className="ml-2" />
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="relative">
          <Img
            className="z-30 w-72 h-auto rounded-md shadow-xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[1].node.childImageSharp.fluid}
          />
          <Img
            className="absolute -top-6 left-16 sm:left-36 z-20 w-72 h-auto rounded-sm transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[0].node.childImageSharp.fluid}
          />
          {/* <Img
            className="absolute -top-8 sm:left-24 z-10 w-52 h-auto rounded-sm transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[2].node.childImageSharp.fluid}
          /> */}
        </div>
      </div>
    </WorkContainer>
  )
}
