import React from "react"

interface IProps {
  size?: number
  className?: string
}

export default function TrainSpotterLogo({ size = 201, className }: IProps) {
  return (
    <svg
      width={size}
      height={(280 / 968) * size}
      viewBox="0 0 968 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="140" cy="140" r="140" fill="#D83E50" />
      <circle cx="484" cy="140" r="140" fill="#F1C84B" />
      <circle cx="828" cy="140" r="140" fill="#63B05A" />
    </svg>
  )
}
