import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import IconDoubleChevronRight from "../Icons/IconDoubleChevronRight"
import WorkContainer from "./WorkContainer"

export default function Preferr() {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "preferr" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <WorkContainer>
      <div className="">
        <div className="flex items-end">
          <div className="text-xl font-sans">Preferr</div>
        </div>
        <div className="text-xs mb-4 font-mono text-gray-500">
          <a
            href="https://preferr.app"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block transform ease-in-out duration-300 hover:translate-x-1 hover:text-gray-50"
          >
            preferr.app
          </a>
        </div>
        <div className="text-base font-sans text-gray-500">Founder</div>
        <div className="font-mono text-gray-500 text-sm">2020 - Present</div>
        <div className="font-sans text-sm mt-6">
          <p>Preferr is an A/B testing tool for React.</p>
          <p>
            I wanted to find a way to blend my passion for good design and well
            crafted developer tools, and Preferr became that outlet.
          </p>
          <p>
            I wasn't satisfied with the A/B testing software available in the
            market, and decided to blend my love of design and development to
            build my own A/B testing service.
          </p>
          <p>
            I created a GraphQL API using Ruby on Rails, a React frontend, and
            released a JavaScript (with TypeScript support of course) client
            library to make Preferr easy to implement for any React developer.
          </p>
        </div>
        <Link
          to="/preferr"
          className="flex items-center font-mono text-gray-500 hover:text-gray-50 text-sm"
        >
          <div>More about Preferr</div>
          <IconDoubleChevronRight size={16} className="ml-2" />
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="relative">
          <Img
            className="z-30 w-72 h-auto rounded-md shadow-2xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[1].node.childImageSharp.fluid}
          />
          <Img
            className="absolute top-8 left-16 sm:left-36 z-20 w-60 h-auto rounded-md shadow-2xl transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[0].node.childImageSharp.fluid}
          />
          {/* <Img
            className="absolute -top-8 sm:left-24 z-10 w-52 h-auto rounded-sm transform transition-scale ease-in-out duration-700 hover:scale-105"
            fluid={data.allFile.edges[2].node.childImageSharp.fluid}
          /> */}
        </div>
      </div>
    </WorkContainer>
  )
}
